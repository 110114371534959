import React from "react";
import PropTypes from "prop-types";
import {
	FacebookIcon,
	FacebookShareButton,
	OKIcon,
	OKShareButton,
	TwitterIcon,
	TwitterShareButton,
	VKIcon,
	VKShareButton
} from "react-share";

import "./share-link.scss";

const ShareArticle = ({ size = 40, title, url, image }) => {
	return (
		<div className="social-share">
			<h4 className="hidden-md social-share__title">Поделиться</h4>
			<div className="social-share__list">
				<VKShareButton image={image} title={title} url={url}>
					<VKIcon size={size} round={true} />
				</VKShareButton>
				<FacebookShareButton quote={title} url={url}>
					<FacebookIcon size={size} round={true} />
				</FacebookShareButton>
				<OKShareButton title={title} url={url}>
					<OKIcon size={size} round={true} />
				</OKShareButton>
				<TwitterShareButton title={title} url={url}>
					<TwitterIcon size={size} round={true} />
				</TwitterShareButton>
			</div>
		</div>
	);
};

ShareArticle.propTypes = {
	size: PropTypes.number,
	title: PropTypes.string,
	url: PropTypes.string
};

export default ShareArticle;
