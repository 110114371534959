import React from "react";
import { graphql } from "gatsby";
import { DateTime } from "luxon";
import ReactMarkdown from "react-markdown";

// components
import BottomLineWithLink from "@components/bottom-line-with-link";
import Layout from "@components/layout/layout";
import ShareLink from "./share-article";

// css
import "./article-template.scss";

// img
import defaultImage from "@img/ic-empty-news.png";

const ArticleTemplate = ({ data = {}, pageContext, location, ...rest }) => {
	const { markdownRemark: { frontmatter = {} } = {} } = data;
	const { next: { frontmatter: { path: nextPath } = {} } = {} } = pageContext;
	const { title, content, publishedAt, image } = frontmatter;
	const dt = DateTime.fromISO(publishedAt)
		.setLocale("ru")
		.toLocaleString(DateTime.DATE_MED);

	return (
		<Layout location={location} {...rest}>
			<section className="full-width news-template">
				<div className="grid-container">
					<div className="row">
						<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
							<h2 className="news-template__header">{title}</h2>
							<div className="news-template__date">{dt}</div>
							<ReactMarkdown source={content} />
						</div>

						<div className="col-xs-12 col-md-offset-1 col-md-1 col-sm-offset-1 col-sm-10">
							<ShareLink
								image={`${location.origin}/${image || defaultImage}`}
								title={title}
								url={location.href}
							/>
						</div>
					</div>
				</div>
			</section>
			{nextPath && (
				<BottomLineWithLink to={nextPath} title="Следующая новость" />
			)}
		</Layout>
	);
};

export const pageQuery = graphql`
	query($path: String!) {
		markdownRemark(frontmatter: { path: { eq: $path } }) {
			frontmatter {
				path
				title
				content
				publishedAt
				image
			}
		}
	}
`;

export default ArticleTemplate;
